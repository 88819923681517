import React from "react";
import { graphql } from 'gatsby'
import AOS from 'aos';

import PrimaryLayout from '../layouts/PrimaryLayout';
import SEO from '../layouts/SEO';

export default function PrivacyPolicy({data}) {
  if (typeof window !== 'undefined') {
    AOS.init({ duration : 800});
  }
  return (
    <PrimaryLayout>
      <SEO 
        title={data.wordpressPage.acf.seo_title}
        description={data.wordpressPage.acf.seo_description}
        keywords={data.wordpressPage.acf.seo_keywords}
      />
      <div class="container-fluid">
        <div class="row p-0 m-0">
          <div class="container main-container-padding-t-5 main-container-padding-b-2">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="header-text-container"
                  data-aos="fade-up"
                >
                  <div class="vertical-center">
                    <h1
                      data-aos="fade-up"
                      dangerouslySetInnerHTML={{__html: data.wordpressPage.title}}
                     />
                  </div>
                </div>
              </div>
              <div
                class="col-md-6"
                data-aos="fade"
              ></div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid primary-color-bg ">
        <div class="row p-0 m-0">
          <div class="container main-container-padding-t-5 main-container-padding-b-2">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="header-text-container"
                  data-aos="fade-up"
                >
                  <div class="vertical-center" dangerouslySetInnerHTML={{__html: data.wordpressPage.content}} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
}



export const query = graphql`
{
  wordpressPage(slug: {eq: "privacy-policy"}) {
    content
    title
    acf{
      seo_title
      seo_keywords
      seo_description
    }
  }
}
`